import { createContext, HTMLAttributes, useState } from "react";

export type ControlPanelContextProps = HTMLAttributes<HTMLElement>;

type ControlPanel = {
  connected: boolean;
  disconnect: () => void;
  showChat: boolean;
  showClientBars: boolean;
  showAudioPlayer: boolean;
};
const initialState: ControlPanel = {
  showChat: true,
  showClientBars: true,
  showAudioPlayer: true,
  connected: false,
  disconnect: () => ({}),
};

export const ControlPanel = createContext({
  ...initialState,
  setData: (newdata) => {},
});

const ControlPanelContext = ({ children }: ControlPanelContextProps) => {
  const [state, setState] = useState<ControlPanel>(initialState);

  return (
    <ControlPanel.Provider
      value={{
        ...state,
        setData: (newState: Partial<ControlPanel>) => {
          setState({ ...state, ...newState });
        },
      }}
    >
      {children}
    </ControlPanel.Provider>
  );
};

export default ControlPanelContext;
