import { createBrowserRouter } from "react-router-dom";

export const makeRoutes = () => {
  return createBrowserRouter([
    {
      path: "/",
      lazy: () => import("./root"),
      children: [
        {
          path: "/",
          lazy: () => import("./console-page"),
        },
      ],
    },

    {
      path: "*",
      lazy: () => import("./not-found"),
    },
  ]);
};
